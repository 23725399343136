import { Component, OnInit } from '@angular/core';
import { Brand } from 'src/app/shared/interfaces/brand';

@Component({
  selector: 'app-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.scss'],
})
export class CreateBrandComponent implements OnInit {
  constructor() {}
  showValues = false;
  sendValues: Brand;
  
  ngOnInit(): void {}

  submit(description: string, tokenRedeemLanding: string) {
    this.sendValues = {
      Description: description,
      TokenRedeemLanding: tokenRedeemLanding,
      amountOfCampaigns: 0
    }
  }
}
