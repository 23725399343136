import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(
    public router: Router,
  ) { }
  
  setUserData(userData) {
    console.log("setting user data...")
    localStorage.setItem('userData', JSON.stringify(userData))
  }
  getUserData() {
    return JSON.parse(localStorage.getItem('userData'))
  }

  setBrands(brands) {
    localStorage.setItem('brands', JSON.stringify(brands))
  }
  getBrands() {
    return JSON.parse(localStorage.getItem('brands'))
  }
}
