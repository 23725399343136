import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/data/data.service';
import { ActivatedRoute } from '@angular/router';
import {Campaign} from "../../../shared/interfaces/campaign";
import { Allocation } from 'src/app/shared/interfaces/allocation';
import { Token } from '@angular/compiler/src/ml_parser/lexer';

@Component({
  selector: 'app-view-allocation',
  templateUrl: './view-allocation.component.html',
  styleUrls: ['./view-allocation.component.scss'],
})
export class ViewAllocationComponent implements OnInit {
  allocation: Allocation;
  tokens: any[];
  campaignId: string;
  brandId: string;
  allocationId: string;
  parent: Campaign;

  constructor(
    public dataService: DataService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((data) => {
      this.brandId = data['params']['brandId'];
      this.campaignId = data['params']['campaignId'];
      this.allocationId = data['params']['allocationId'];
    });
    this.getAllocation();
    this.getTokens();
    this.getParent();
  }

  getParent() {
    this.dataService.getCampaign(this.brandId, this.campaignId)
    .subscribe((campaign) => {
      this.parent = campaign
    })
  }
  getAllocation() {
    this.dataService
      .getAllocation(this.brandId, this.campaignId, this.allocationId)
      .subscribe((allocation) => {
        this.allocation = allocation;
      });
  }

  getTokens() {
    this.dataService
      .getTokens(this.brandId, this.campaignId, this.allocationId)
      .subscribe((tokens) => {
        this.tokens = tokens;
      });
  }
}
