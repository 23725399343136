import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/data/data.service';
import { ActivatedRoute } from '@angular/router';
import {StateService} from "../../../shared/services/state/state.service";
import {Brand} from "../../../shared/interfaces/brand";

@Component({
  selector: 'app-view-campaign',
  templateUrl: './view-campaign.component.html',
  styleUrls: ['./view-campaign.component.scss'],
})
export class ViewCampaignComponent implements OnInit {
  campaign: any;
  allocations: any;
  campaignId: string;
  brandId: string;
  parent: Brand;

  constructor(
    public dataService: DataService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public stateService: StateService,
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((data) => {
      this.brandId = data['params']['brandId'];
      this.campaignId = data['params']['campaignId'];
    });
    this.getCampaign();
    this.getAllocations();
    this.getParent()
  }
  getParent() {
    this.dataService
      .getBrand(this.brandId)
      .subscribe((brand) => {
        this.parent = brand;
      });
  }
  getCampaign() {
    this.dataService
      .getCampaign(this.brandId, this.campaignId)
      .subscribe((campaign) => {
        this.campaign = campaign;
      });
  }
  getAllocations() {
    this.dataService
      .getAllocations(this.brandId, this.campaignId)
      .subscribe((allocations) => {
        this.allocations = allocations;
      });
  }
}
