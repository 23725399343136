<div class="container-fluid" *ngIf="this.authService.userData.displayName">
  <div class="row">
    <div class="col">
      <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">User Profile</h1>
      </div>
      <h1>Ingelogd als {{ authService.userData.displayName }}</h1>
      <span class="btn btn-danger" (click)="authService.SignOut()">Logout</span>
      <span class="btn-primary btn" (click)="viewUserObj = !viewUserObj">View UserObj</span>
      <pre *ngIf="viewUserObj">{{ authService.userData | json }}</pre>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h1>Alle Brands:</h1>
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Description</th>
            <th>TokenRedeemLanding</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let brand of brands">
            <td>
              <a [routerLink]="'/dashboard/view/brand/' + brand.eventId">{{brand.eventId}}</a>
            </td>
            <td>{{ brand.Description }}</td>
            <td>{{ brand.TokenRedeemLanding }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a [routerLink]="'/dashboard/create/brand'">Add New Brand</a>
    </div>
  </div>
</div>
