import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/guard/auth.guard';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './pages/auth/verify-email/verify-email.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ViewBrandComponent } from './pages/view/view-brand/view-brand.component';
import { ViewCampaignComponent } from './pages/view/view-campaign/view-campaign.component';
import { ViewAllocationComponent } from './pages/view/view-allocation/view-allocation.component';
import { CreateTokenComponent } from './pages/create/create-token/create-token.component';
import { CreateCampaignComponent } from './pages/create/create-campaign/create-campaign.component';
import { CreateAllocationComponent } from './pages/create/create-allocation/create-allocation.component';
import { CreateBrandComponent } from './pages/create/create-brand/create-brand.component';

const routes: Routes = [
  //Auth Routes
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'register-user', component: SignUpComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },

  //create Routes
  {
    path: 'dashboard/create/brand',
    component: CreateBrandComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/create/campaign/:brandId',
    component: CreateCampaignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/create/allocation/:brandId/:campaignId',
    component: CreateAllocationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/create/token/:brandId/:campaignId/:allocationId',
    component: CreateTokenComponent,
    canActivate: [AuthGuard],
  },

  //view Routes
  {
    path: 'dashboard/view/brand/:brandId',
    component: ViewBrandComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/view/campaign/:brandId/:campaignId',
    component: ViewCampaignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/view/allocation/:brandId/:campaignId/:allocationId',
    component: ViewAllocationComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
