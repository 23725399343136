import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Brand } from '../../interfaces/brand';
import {StateService} from "../state/state.service";
import {Campaign} from "../../interfaces/campaign";
import {Allocation} from "../../interfaces/allocation";
import {Token} from "../../interfaces/token";
@Injectable({
  providedIn: 'root',
})
export class DataService {
  items: Observable<any[]>;

  constructor(
    public afs: AngularFirestore,
    private state: StateService,
  ) {}

  getBrands(): Observable<Brand[]> {
    return this.afs
      .collection<any>('fairchain-brands')
      .valueChanges({ idField: 'eventId' });
  }
  getBrand(brandID: string): Observable<Brand> {
    return this.afs
      .doc('fairchain-brands/' + brandID)
      .valueChanges({ idField: 'eventId' }) as Observable<Brand>;
  }
  getCampaigns(brandID): Observable<Campaign[]> {
    return this.afs
      .collection<any>('fairchain-brands/' + brandID + '/campains') //fix typo
      .valueChanges({ idField: 'eventId' }) as Observable<Campaign[]>;
  }
  getCampaign(brandID, campaignID): Observable<Campaign> {
    return this.afs
      .doc('fairchain-brands/' + brandID + '/campains/' + campaignID)
      .valueChanges({ idField: 'eventId' }) as Observable<Campaign>;
  }

  getAllocations(brandId: string, campaignId: string): Observable<Allocation[]> {
    return this.afs
      .collection(`fairchain-brands/${brandId}/campains/${campaignId}/allocations`)
      .valueChanges({ idField: 'eventId' }) as Observable<Allocation[]>;
  }

  getAllocation(brandID, campaignID, allocationId): Observable<Allocation> {
    return this.afs
      .doc('fairchain-brands/' + brandID + '/campains/' + campaignID + '/allocations/' + allocationId)
      .valueChanges({ idField: 'eventId' }) as Observable<Allocation>;
  }

  getTokens(brandId: string, campaignId: string, allocationId: string): Observable<Token[]> {
    return this.afs
      .collection('fairchain-brands/' + brandId + '/campains/' + campaignId + '/allocations/' + allocationId + '/tokens')
      .valueChanges({ idField: 'eventId' }) as Observable<Token[]>;
  }
}
