<div class="container">
  <div class="row">
    <div class="col">
      <p class="text-muted"><app-trail [brand]='brandId'></app-trail></p>
      <h1>Viewing Brand: {{ brand.Description }}</h1>
      <h2>{{ brand.TokenRedeemLanding }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <hr />
      <h4>Brand has Campaigns:</h4>
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Description</th>
            <th>Amount of allocations</th>
            <th>Amount of tokens (all allocations)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let campaign of campaigns">
            <td>
              <a [routerLink]="'/dashboard/view/campaign/' + brandId + '/' + campaign.eventId">{{ campaign.eventId }}</a>
            </td>
            <td>{{ campaign.Description }}</td>
            <td>{{ campaign.children }}</td>
            <td>{{ campaign.children }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a [routerLink]="['/dashboard/create/campaign', brandId]">Add New Campaign</a>
    </div>
  </div>
</div>
