import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-allocation',
  templateUrl: './create-allocation.component.html',
  styleUrls: ['./create-allocation.component.scss'],
})
export class CreateAllocationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
