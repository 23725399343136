import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth-service.service';
import { DataService } from '../../shared/services/data/data.service';
import { Brand } from '../../shared/interfaces/brand';
import {StateService} from "../../shared/services/state/state.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  viewUserObj = false;
  brands: Brand[];
  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public stateService: StateService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getBrands();
  }
  async getBrands() {
    this.dataService.getBrands().subscribe((brands) => {
      this.brands = brands as Brand[];
    });
  }
}
