<div class="container">
    <div class="row">
        <div class="col">
            <h1>Create a new Brand</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <form #Form>
                <div class="form-group row">
                  <label for="Description" class="col-4 col-form-label">Description</label>
                  <div class="col-8">
                    <input id="Description" name="Description" placeholder="MoyeeCoffee" type="text" class="form-control" #Description>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="TokenRedeemLanding" class="col-4 col-form-label">TokenRedeemLanding</label>
                  <div class="col-8">
                    <input id="TokenRedeemLanding" name="TokenRedeemLanding" placeholder="https://redeemtoken.dev.moyeecoffee.com" type="text" class="form-control" #TokenRedeemLanding>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="offset-4 col-8">
                    <span name="submit" class="btn btn-primary" (click)="submit(Description.value, TokenRedeemLanding.value)">Submit</span>
                  </div>
                </div>
              </form>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <span class="btn btn-info" (click)="showValues = !showValues">Show values</span>
            <div class="div" *ngIf="showValues">{{sendValues | json}}</div>
        </div>
    </div>
</div>
