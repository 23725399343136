<div class="container">
  <div class="row">
    <div class="col">
      <p class="text-muted"><app-trail [brand]='brandId' [campaign]='campaignId' [allocation]='allocationId'></app-trail></p>
      <h1>Viewing Allocation: {{ allocation.Description }}</h1>
      <h4>Child of Campaign: <a >{{parent.Description}}</a></h4>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <hr />
      <h4>Allocation has Tokens:</h4>
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>TokenID</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let token of tokens">
            <td>{{ token.eventId }}</td>
            <td>{{ token.Description }}</td>
            <td>{{ token.TokenID }}</td>
            <td>{{ token.URL }}</td>
            <td><qrcode [qrdata]="token.URL" [width]="128" [errorCorrectionLevel]="'M'"></qrcode></td>
          </tr>
        </tbody>
      </table>
    </div> 
  </div>
  <div class="row">
    <div class="col">
      <a [routerLink]="['/dashboard/create/token', brandId, campaignId, allocationId]">Add New Token(s)</a>
    </div>
  </div>
</div>
