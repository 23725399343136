import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './pages/auth/verify-email/verify-email.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { AuthService } from './shared/services/auth/auth-service.service';

import { CreateBrandComponent } from './pages/create/create-brand/create-brand.component';
import { CreateCampaignComponent } from './pages/create/create-campaign/create-campaign.component';
import { CreateAllocationComponent } from './pages/create/create-allocation/create-allocation.component';

import { ViewBrandComponent } from './pages/view/view-brand/view-brand.component';
import { ViewCampaignComponent } from './pages/view/view-campaign/view-campaign.component';
import { ViewAllocationComponent } from './pages/view/view-allocation/view-allocation.component';
import { MenuComponent } from './components/menu/menu.component';

import { QRCodeModule } from 'angularx-qrcode';
import { TrailComponent } from './components/trail/trail.component';
import { CreateTokenComponent } from './pages/create/create-token/create-token.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,

    CreateBrandComponent,
    CreateCampaignComponent,
    CreateAllocationComponent,

    ViewBrandComponent,
    ViewCampaignComponent,
    ViewAllocationComponent,
    MenuComponent,
    TrailComponent,
    CreateTokenComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    QRCodeModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
