import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/data/data.service';
import { Brand } from '../../../shared/interfaces/brand';
import { ActivatedRoute } from '@angular/router';
import {StateService} from "../../../shared/services/state/state.service";

@Component({
  selector: 'app-view-brand',
  templateUrl: './view-brand.component.html',
  styleUrls: ['./view-brand.component.scss'],
})
export class ViewBrandComponent implements OnInit {
  brand: Brand;
  campaigns: any;
  brandId: string;

  constructor(
    public dataService: DataService,
    public stateService: StateService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((data) => {
      this.brandId = data['params']['brandId'];
    });
    this.getBrand();
    this.getCampaigns();
  }
  getBrand() {
    this.dataService.getBrand(this.brandId).subscribe((brand) => {
      this.brand = brand;
    });
  }
  getCampaigns() {
    this.dataService.getCampaigns(this.brandId).subscribe((campaigns) => {
      this.campaigns = campaigns;
    });
  }
}
