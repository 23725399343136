<form class="form-signin text-center">
  <img
    src="https://mk0fairchainorgou7il.kinstacdn.com/wp-content/uploads/2019/06/Logo_FC_BC.png"
    style="height: 200px"
    alt="fairChain"
  />
  <br />
  <label for="inputEmail" class="sr-only"></label>
  <input
    #userName
    type="email"
    id="inputEmail"
    class="form-control"
    placeholder="Email address"
    required
    autofocus
  />
  <label for="inputPassword" class="sr-only"></label>
  <input
    #userPassword
    type="password"
    id="inputPassword"
    class="form-control"
    placeholder="Password"
    required
  />
  <div class="checkbox mb-3"></div>
  <span
    class="btn btn-lg btn-primary btn-block"
    (click)="authService.SignIn(userName.value, userPassword.value)"
    >Sign in</span
  >
  <br />
  Or
  <br />
  <img
    src="assets/google.png"
    class="btn googleBtn"
    (click)="authService.GoogleAuth()"
    alt="googleAuthButton"
    style="width: 300px"
  />
</form>

<span class="btn btn-primary" routerLink="/forgot-password"
  >Forgot Password?</span
>
<span class="btn btn-primary redirect" routerLink="/register-user">
  Sign Up</span
>
