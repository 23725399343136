<nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-5" *ngIf="this.authService.isLoggedIn">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/">
      <img src="https://mk0fairchainorgou7il.kinstacdn.com/wp-content/uploads/2019/06/Logo_FC_BC.png"  height="30" class="d-inline-block align-top" alt="">
    </a>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav" v-if="getLogged">
        <li class="nav-item">
          <a routerLink="/" class="nav-link" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Add
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a routerLink="/create/job" class="dropdown-item" href="#">Job</a></li>
            <li><a routerLink="/create/client" class="dropdown-item" href="#">Client</a></li>
            <li><a routerLink="/create/expense" class="dropdown-item" href="#">Expense</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <form class="d-flex">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="navbar-brand mx-0" href="#">
            <img src="{{authService.userData.photoURL}}" width="30" height="30" class="d-inline-block rounded-circle" alt="">
            <span>{{authService.userData.displayName}}</span>
            <span class="btn btn-danger" (click)="authService.SignOut()">Logout</span>
          </a>
        </li>
      </ul>
    </form>
  </div>
</nav>
