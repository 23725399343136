<div class="container">
  <div class="row">
    <div class="col">
      <p class="text-muted"><app-trail [brand]='brandId' [campaign]='campaignId'></app-trail></p>
      <h1>Viewing Campaign: {{ campaign.Description }}</h1>
      <h4>Child of {{parent.Description}}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <hr />
      <h4>Campaign has Allocations:</h4>
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Description</th>
            <th>Amount of tokens</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let allocation of allocations">
            <td>
              <a [routerLink]="'/dashboard/view/allocation/' + brandId + '/' + campaignId + '/' + allocation.eventId">{{allocation.eventId}}</a>
            </td>
            <td>{{ allocation.Description }}</td>
            <td>{{ allocation.children }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a [routerLink]="['/dashboard/create/allocation', brandId, campaignId]">Add New Allocation</a>
    </div>
  </div>
</div>
