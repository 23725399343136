import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-trail',
  template: `
  <span>
    <a [routerLink]="'/dashboard/view/brand/' + brand ">{{brand}}</a>
  </span>
  
  <span *ngIf="campaign"> > <a [routerLink]="'/dashboard/view/campaign/' + brand + '/' + campaign">{{campaign}}</a></span>
    
  <span *ngIf="allocation"> > <a [routerLink]="'/dashboard/view/allocation/' + brand + '/' + campaign + '/' + allocation">{{allocation}}</a></span>
  
  `,
  styleUrls: ['./trail.component.scss']
})
export class TrailComponent implements OnInit {

  constructor() { }
  @Input() brand: String;
  @Input() campaign!: String;
  @Input() allocation!: String;
  @Input() token!: String;
  ngOnInit(): void {
  }

}
